@import 'style/variables/colors';

.root {
  // display: flex;
  // flex-direction: column;

  &__isDisabled {
    pointer-events: none;
    opacity: 0.3;
  }
}

.label {
  font-weight: 600;
}

.section {

}

.content {
  // display: flex;
  // align-items: center;
}

.prefix,
.postfix {
  margin-right: 20px;
  font-size: $font-sm;
  font-weight: bold;
  color: #000
}

.prefix {
  margin-right: 20px;
}

.postfix {
  margin-left: 20px;
}

.error {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  font-size: $font-xs;
  color: $red;
  white-space: nowrap;
}

.control {
  position: relative;
  font-weight: 500;
  border-radius: $radius-2;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid transparent;
    box-shadow: none;
  }

  :global {
    .ant-input-number {
      border: none;
      background: transparent;
      box-shadow: none;
      padding: 0;
      font-size: inherit;
      line-height: initial;
      border-radius: inherit;
      color: inherit;
      width: 100%;

      &-handler-wrap {
        display: none;
      }

      &-input {
        height: 100%;
        padding: 0;
        font-size: inherit;
        font-weight: inherit;
      }

      &-input-wrap {
        height: 100%;
      }

      &:focus,
      &:hover {
        border: none;
        box-shadow: none;
      }
    }
  }

  &__hasError {
    .control {
      border-color: $red;

      &:hover {
        border-color: $red;
        box-shadow: none;
      }
    }
  }
}

.root {
  &__style {
    &_white {
      .label {
        color: $brown;
      }

      .control {
        background: $white;
        color: $brown-light;
        border: 2px solid $brown;

        &:hover {
          border: 2px solid $brown;
        }
      }
    }

    &_gray-light{
      .label {
        color: $brown;
      }

      .control {
        background: $gray-light;
        color: $brown-light;
        border: 2px solid $brown;

        &:hover {
          border: 2px solid $brown;
        }
      }
    }
  }

  &__size {
    &_xs {
      .label {
        font-size: $font-xs;
        // margin-bottom: $font-xs;
        margin-bottom: 4px;
      }

      .control {
        padding: calc($font-xs / 2) $font-xs;
        font-size: $font-xs;
      }
    }

    &_sm {
      .label {
        font-size: $font-sm;
        // margin-bottom: $font-sm;
        margin-bottom: 4px;
      }

      .control {
        padding: calc($font-sm / 2) $font-sm;
        font-size: $font-sm;
      }
    }

    &_md {
      .label {
        font-size: $font-md;
        // margin-bottom: $font-md;
        margin-bottom: 4px;
      }

      .control {
        padding: calc($font-md / 2) $font-md;
        font-size: $font-md;
      }
    }

    &_lg {
      .label {
        font-size: $font-lg;
        // margin-bottom: $font-lg;
        margin-bottom: 4px;
      }

      .control {
        padding: calc($font-lg / 2) $font-lg;
        font-size: $font-lg;
      }
    }
  }
}


// .root {
// }

// .section {
//   display: flex;
//   align-items: center;
// }

// .label {
//   font-size: $font-sm;
//   font-weight: bold;
//   color: #000;
//   margin-bottom: 14px;
// }

// .prefix {
//   margin-right: 20px;
//   font-size: $font-sm;
//   font-weight: bold;
//   color: #000
// }

// .control {
//   position: relative;

//   :global {
//     .ant-input-number {
//       height: 50px;
//       background: #fff;
//       font-size: $font-sm;
//       color: #000;
//       padding: 0 20px;
//       border-radius: $radius;
//       border: 1px solid transparent;

//       &-handler-wrap {
//         display: none;
//       }

//       &-input {
//         height: 100%;
//         padding: 0;
//       }

//       &-input-wrap {
//         height: 100%;
//       }

//       &:focus,
//       &:hover {
//         border: 1px solid transparent;
//         box-shadow: none;
//       }
//     }
//   }

//   &__hasError {
//     :global {
//       .ant-input-number {
//         border-color: $red;

//         &:focus,
//         &:hover {
//           border-color: $red;
//           box-shadow: none;
//         }
//       }
//     }
//   }
// }

// .error {
//   position: absolute;
//   top: calc(100% + 2px);
//   left: 0;
//   font-size: 12px;
//   color: $red;
//   white-space: nowrap;
// }

// .postfix {
//   margin-left: 20px;
//   font-size: $font-sm;
//   font-weight: bold;
//   color: #000
// }