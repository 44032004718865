@import 'style/mixins/icons';

.root {
  display: inline-block;
  cursor: pointer;
  margin: 0 8px;

  &::before {
    @include copy;
    display: block;
    content: '';
  }
}
