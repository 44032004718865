@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  flex-direction: column;
  // padding: 28px 36px;
}

.header {
  display: flex;
  justify-content: space-between;
  
  &_left {}

  &_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.actions {
  margin-left: 40px;
}

.roomName {
  font-size: $font-md;
  font-weight: 600;
  color: $brown;
}

.clubName {
  display: flex;
  font-size: $font-sm;
  color: $brown;
  margin-top: 15px;

  span {
    margin-right: 8px;
  }

  a {
    font-weight: 500;
  }
}

.status {
  display: flex;
  font-size: $font-md;

  &_title {
    font-weight: 500;
    margin-right: 4px;
    color: $brown-light;
  }

  &_value {
    font-weight: 600;
    color: $brown;
  }
}

.date {
  margin-left: 8px;

  display: flex;
  font-size: $font-md;

  &_title {
    font-weight: 500;
    margin-right: 4px;
    color: $brown-light;
  }

  &_value {
    font-weight: 600;
    color: $brown;
  }
}

.content {
  display: flex;
  margin-top: 32px;
}

.section {
  flex: 1;

  &_title {
    font-size: $font-sm;
    font-weight: 500;
    font-style: italic;
    color: $brown-light;
    margin-bottom: 35px;
  }

  &_content {
    display: flex;
  }

  &__reward {
    flex: 1;
  }

  &__metrics {
    flex: 2;
  } 
}

.details {
  flex: 1;
}


.detail {
  padding: 4px;
  
  &_name {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown-light;
    margin-bottom: 15px;
  }

  &_value {
    font-size: $font-sm;
    font-weight: 600;
    color: $brown;

    &__highlighted {
      color: $blue-light;
    }
  }

  & + & {
    margin-top: 35px;
  }
}

@media (max-width: $sm) {
  .root {

  }

  .header {

    &_left {
      flex: 2;
    }

    &_right {
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .actions {
    margin: 0;
    margin-top: 8px;
  }

  .roomName {
    font-size: $font-sm;
  }

  .clubName {
    font-size: $font-xs;
    margin-top: 10px;

    span {
      margin-right: 8px;
    }
  }

  .status {
    font-size: $font-xs;

    &_title {
      margin-right: 4px;
    }

    &_value {
    }
  }

  .date {
    font-size: $font-xs;
    margin-left: 0;
    margin-top: 9px;

    &_title {
      margin-right: 4px;
    }

    &_value {
    }
  }

  .content {
    flex-direction: column;
    margin-top: 20px;
  }

  .section {
    &_title {
      font-size: $font-sm;
      margin-bottom: 20px;
    }

    &_content {
      flex: initial;
      flex-direction: column;
    }

    &__reward {
      flex: initial;
    }

    &__metrics {
      flex: initial;
    } 

    & + & {
      margin-top: 25px;
    }    
  }

  .details {
    display: flex;
    flex-wrap: wrap;

    & + & {
      margin-top: 10px;
    }
  }


  .detail {
    flex: 1 1 50%;
    max-width: 50%;
    padding: 5px;

    &_name {
      font-size: $font-xs;
      margin-bottom: 15px;
    }

    &_value {
      font-size: $font-xs;
    }

    & + & {
      margin: 0;
    }
  }
}