@import 'style/variables/colors';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;

  &_section {
    display: flex;
    align-items: center;
  }
}

.links {
  display: flex;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: $gray-light;
  height: 50px;
  min-width: 50px;

  & + & {
    margin-left: 20px;
  }

  &__clubhouse  {
    &::before {
      @include clubhouse;
    }
    
  }

  &__github {
    &::before {
      @include github;
    }
    
  }

  &__bscscan {
    &::before {
      @include bscscan;
    }
    
  }

  &__telegram {
    background-color: $telegram;

    &::before {
      @include telegram;
    }
    
  }

  &__twitter {
    background-color: $twitter;

    &::before {
      @include twitter;
    }
    
  }

  &__instagram {
    background-color: $instagram;

    &::before {
      @include instagram;
    }
  }

  &__phone {
    &::before {
      @include phone;
    }
  }

  &__faq {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    &:hover {
      text-decoration: initial;
    }
        
    &::before {
      content: 'FAQ' !important;
      font-weight: 600;
      font-size: $font-md;
      color: $brown-light;
      width: auto !important;
      height: auto !important;
    }
  }

  &::before {
    display: block;
    content: '';
    height: 30px;
    width: 30px;
  }
}

.logo {
  margin-left: 55px;

  &::before {
    display: block;
    content: '';
    @include logo-2;
  }
}

@media (max-width: $sm) {
  .root {
    flex-direction: column;
    padding: 20px 0;

    &_section {
      justify-content: space-between;
      
      & + & {
        margin-top: 20px;
      }
    }
  }
  
  .link {
    height: 30px;
    min-width: 30px;

    &::before {
      width: 18px;
      height: 18px;
    }

    & + & {
      margin-left: 16px;
    }

    &__faq {
      padding: $font-xs;

      &::before {
        font-size: $font-xs;
        height: 30px !important;
        line-height: 30px !important;
      }
    }
  }

  .logo {
    margin: 0;

    &::before {
      height: 30px;
      width: 90px;
    }
  }
}