@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  align-items: center;
  color: inherit;

  &_text {
    margin-right: 4px;
  }

  &:hover {
    .root_text {
      color: inherit;
    }
  }

  &_icon {
    &::before {
      display: block;
      content: '';
      @include external-link;
      height: 13px;
      width: 13px;
    }
  }
}