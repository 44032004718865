@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.modalWrap {}

.modal {
  &:global(.ant-modal) {
    width: map-get($containers, 'sm') !important;
  }

  :global {
    .ant-modal-title {
      line-height: initial;
    }

    .ant-modal-header {
      display: none;
    }
    
    .ant-modal-content {
      box-shadow: none;
      border-radius: $radius-2;
    }

    .ant-modal-body {
      padding: 0;
      font-size: initial;
      line-height: initial;
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  padding: 28px 40px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: center;
  font-size: $font-md;
  font-weight: 600;
  margin-bottom: 40px;
}



.section {
  flex: 1;

  &_content {
    // display: flex;
  }

  & + & {
    margin-top: 20px;
  }
}

.details {
  flex: 1;
}

.detail {
  display: flex;
  justify-content: space-between;

  &_name {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown-light;
  }

  &_value {
    font-size: $font-sm;
    font-weight: 600;
    color: $brown-light;
  }

  & + & {
    margin-top: 10px;
  }
}

.actions {
  display: flex;
  
  &_action {
    width: 100%;
    text-align: center;

    & + & {
      margin-left: 8px;
    }
  }
}