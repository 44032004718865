@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  padding: 32px 0;
  font-size: $font-md;
}

.fullLogo {
  display: flex;
  position: relative;

  &::before {
    display: block;
    content: 'testnet';
    position: absolute;
    bottom: 100%;
    left: 100%;
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    color: #fff;
    background: $red;
    border-radius: $radius-2;
    padding: 2px 6px;
  }

  &_logo1 {
    &::before {
      display: block;
      content: '';
      @include logo-1;
      height: 49px;
      width: 49px;
    }
  }

  &_logo2 {
    margin-left: 16px;

    &::before {
      display: block;
      content: '';
      @include logo-2-dark;
      height: 49px;
      width: 145px;
    }
  }
}

.web3Account {
  margin-right: 20px;

  &_authorized {

  }

  &_actions {

  }

  &_action {

  }
}

.web3AccountAuthorized {
  display: flex;
  flex-direction: column;

  &_balance {
    display: flex;
    align-items: center;
    
    div {
      font-size: inherit;
      font-weight: 600;
      color: $brown-light;
    }

    span {
      font-size: inherit;
      font-weight: 500;
      color: $brown-light;
      margin-left: 8px;
    }
  }

  &_address {
    font-size: inherit;
    font-weight: 500;
    color: $brown-light;
    margin-top: 8px;
  }
}

.account {
  display: flex;

  &_actions {
    display: flex;
    align-items: center;
  }

  &_action {
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: 600;
    color: $brown-light;
    margin-left: 16px;

    & + & {
      margin-left: 8px;
    }

    &:hover {
      color: $blue-light;
      text-decoration: underline;
    }

    &__isActive {
      color: $blue-light;
    }

    &__signOut {

    }

    &__signIn {

    }

    &__signUp {

    }
  }
}

.accountAuthorized {
  display: flex;
  align-items: center;
  color: $black;

  &_username,
  &_email {
    font-size: inherit;
    font-weight: 500;
    margin-left: 8px;
  }

  &_avatar {}

  &__isActive {
    .accountAuthorized_username,
    .accountAuthorized_email {
      color: $blue-light
    }
  }
}

.avatar {}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_left {
    display: flex;
    align-items: center;
  }

  &_right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.signOutBtn {
  margin-left: 8px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

@media (max-width: $sm) {
  .root {
    padding: 16px 0;
    height: 80px;
    font-size: $font-xs;
  }

  .layout {
    &_left {

    }

    &_right {
      margin-left: 8px;
    }
  }

  
  .fullLogo {
    &_logo1 {
      &::before {
        height: 30px;
        width: 30px;
      }
    }

    &_logo2 {
      margin-left: 4px;

      &::before {
        height: 30px;
        width: 90px;
      }
    }
  }

  .web3Account {
    margin-right: 8px;

    &_authorized {

    }

    &_actions {

    }

    &_action {

    }
  }

  .web3AccountAuthorized {
    &_balance {
      display: none;
    }

    &_address {
      margin-top: 4px;
    }
  }

  .account {
    &_actions {
    }

    &_action {
      margin-left: 8px;

      & + & {
        margin-left: 4px;
      }

      &__signOut {
        // display: none;
      }

      &__signIn {

      }

      &__signUp {

      }
    }
  }

  .accountAuthorized {
    &_username,
    &_email {
      margin-left: 8px;
    }

    &_avatar {}
  }

  .avatar {}

  .signOutBtn {
    margin-left: 8px;
  }
}
