@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.modalWrap {}

.modal {
  &:global(.ant-modal) {
    width: map-get($containers, 'sm') !important;
  }

  :global {
    .ant-modal-title {
      line-height: initial;
    }

    .ant-modal-header {
      display: none;
    }
    
    .ant-modal-content {
      box-shadow: none;
      border-radius: $radius-2;
    }

    .ant-modal-body {
      padding: 0;
      font-size: initial;
      line-height: initial;
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  padding: 28px 40px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: center;
  font-size: $font-md;
  font-weight: 600;
  margin-bottom: 40px;
}

.section {
  flex: 1;

  &_content {
    // display: flex;
  }

  & + & {
    margin-top: 20px;
  }
}

.content {}

.footer {}

.field {
  & + & {
    margin-top: 30px;
  }
}

.actions {
  display: flex;
  
  &_action {
    width: 100%;
    text-align: center;

    & + & {
      margin-left: 8px;
    }
  }
}

.instruction {
  display: flex;
  flex-direction: column;
  
  &_title {
    font-size: $font-sm;
    font-weight: 600;
    margin-bottom: 40px;
    
    span {
      display: block;
    }
  }

  &_code {
    text-align: center;
    font-size: $font-xl;
    font-weight: 600;
  }

  &_highlight {
    display: inline-block;
    font-size: $font-sm;
    font-weight: 600;
  }

  &_link {
    margin: 0 4px;
    color: $blue-light;

    &:hover {
      text-decoration: underline;
    }
  }

  &_text {
    font-size: $font-sm;
    font-weight: 500;

    div {}

    div + div {
      margin-top: 20px;
    }

    span {
      display: block;
      margin-top: 20px;
      color: $gray;
    }
  }
}