$panel: #f1efe4;
$panel-dark: #e6e3d4;
$green: #27ae60;
$primary: #e0e0e0;
$secondary: #808080;
$gray: #808080;
$gray-light: #F3EFE4;
$black: #000;
$white: #fff;
$red: #fe3d2f;
$yellow: #FFB905;
$blue: #0587FF;
$blue-light: #00ACED;
$blue-dark: #4063A7;

$twitter: #00ACED;
$telegram: #27B1F3;
$instagram: #ED2994;

$brown: #333230;
$brown-light: #555349;

$color1: #e2e0d7;
$color2: #eeeeef;

$font-scale: 1.250;
$font-standard: 16px;

$font-sm: $font-standard;
$font-xs: $font-sm / $font-scale;
$font-xxs: $font-xs / $font-scale;

$font-md: $font-sm * $font-scale;
$font-lg: $font-md * $font-scale;
$font-xl: $font-lg * $font-scale;
$font-xxl: $font-xl* $font-scale;

$radius: 6px;
$radius-1: 15px;
$radius-2: 30px;
$radius-3: 42px;
$shadow: 0px 1.5px 6px rgba(51, 50, 48, 0.2);