// @import '@src/styles/mixins/text';
@import 'style/variables/colors';
@import 'style/variables/grid';

.root {
  display: flex;
}

.collectionItem {
  position: relative;
//   @include Body-4Basic500Left;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;

  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    display: block;
    visibility: hidden;
    width: 14px;
    height: 2px;
    // background-color: $submit-400;
  }

  & + & {
    margin-left: 48px;
  }

  &:hover,
  &__active {
    // @include Body-4Basic900Left;
    line-height: inherit;

    &::after {
      visibility: visible;
    }
  }
}

@media (max-width: $md) {
  .collectionItem {
    // @include Body-1Basic500Left;

    &:hover,
    &__active {
    //   @include Body-1Basic900Left;
    }

    &::after {
      bottom: -4px;
    }
  }
}