@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  flex-direction: column;
  // padding: 28px 36px;
}

.header {
  display: flex;
  
  &_left {
    flex: 2;
    display: flex;
    align-items: flex-start;
  }

  &_right {
    flex: 1;
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.avatar {
  margin-right: 20px;
}

.clubName {
  display: flex;
  font-size: $font-md;
  font-weight: 600;
  color: $brown;
}

.chLink {
  display: flex;
  font-size: $font-sm;
  color: $brown;
  margin-top: 15px;

  span {
    margin-right: 8px;
  }

  a {
    font-weight: 500;
  }
}

.content {
  display: flex;
  margin-top: 32px;
}

.section {
  flex: 1;

  &_title {
    font-size: $font-sm;
    font-weight: 500;
    font-style: italic;
    color: $brown-light;
    margin-bottom: 35px;
  }

  &_content {
    display: flex;
  }

  &__reward {
    flex: 2;
  }

  &__staking {
    flex: 2;
  } 

  &__info {
    flex: 1;
  } 
}

.details {
  flex: 1;
}


.detail {
  padding: 4px;
  
  &_name {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown-light;
    margin-bottom: 15px;
  }

  &_value {
    font-size: $font-sm;
    font-weight: 600;
    color: $brown;

    &__highlighted {
      color: $blue-light;
    }
  }

  & + & {
    margin-top: 35px;
  }
}

@media (max-width: $sm) {
  .root {
  }

  .header {
    &_left {

    }

    &_right {
    }
  }

  .avatar {
  }

  .clubName {
    font-size: $font-xs;
  }

  .chLink {
    font-size: $font-xxs;
    margin-top: 10px;

    span {}

    a {}
  }

  .content {
    flex-direction: column;
    margin-top: 20px;
  }

  .section {
    &_title {
      font-size: $font-sm;
      margin-bottom: 20px;
    }

    &_content {}

    &__reward {
      flex: initial;
    }

    &__staking {
      flex: initial;
    } 

    &__info {
      flex: initial;
    } 

    & + & {
      margin-top: 25px;
    }
  }

  .details {
    display: flex;
    flex-wrap: wrap;
  }

  .detail {
    flex: 1 1 50%;
    max-width: 50%;
    padding: 5px;

    &_name {
      font-size: $font-xs;
      margin-bottom: 15px;
    }

    &_value {
      font-size: $font-xs;
    }

    & + & {
      margin: 0;
    }
  }
}