@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  flex-direction: column;
  // padding: 28px 36px;
}

.header {
  display: flex;
  
  &_left {
    display: flex;
    align-items: flex-start;
    // flex: 3;
  }

  // &_right {
  //   flex: 1;
  // }
}

.avatar {
  margin-right: 20px;
}

.names {
  margin-right: 20px;

  &_name {
    font-size: $font-md;
    font-weight: 600;
    color: $brown;
  }

  &_username {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown;
    margin-top: 15px;
  }
}

.content {
  display: flex;
  margin-top: 32px;
}

.section {
  flex: 1;

  &_title {
    font-size: $font-sm;
    font-weight: 500;
    font-style: italic;
    color: $brown-light;
    margin-bottom: 35px;
  }

  &_content {
    display: flex;
  }

  &_footer {
    margin-top: 20px;
  }

  &__reward {
    flex: 2;
  }

  &__metrics {
    flex: 1;
  } 

  & + & {
    margin-left: 6px;
  }
}

.reward {
  &_actions {
    display: flex;
  }

  &_action {
    & + & {
      margin-left: 16px;
    }
  }
}

.metrics {

}

.details {
  flex: 1;
}

.detail {
  &_name {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown-light;
    margin-bottom: 15px;
  }

  &_value {
    font-size: $font-sm;
    font-weight: 600;
    color: $brown;

    &__highlighted {
      color: $blue-light;
    }
  }

  & + & {
    margin-top: 35px;
  }
}

@media (max-width: $sm) {
  .root {
  }

  .header {
    &_left {
    }

    &_right {
    }
  }

  .avatar {
  }

  .names {
    margin-right: 20px;

    &_name {
      font-size: $font-sm;
    }

    &_username {
      font-size: $font-sm;
      margin-top: 15px;
    }
  }

  .content {
    flex-direction: column;
    margin-top: 32px;
  }

  .section {
    flex: 1;

    &_title {
      font-size: $font-sm;
      margin-bottom: 20px;
    }

    &_content {
      display: flex;
    }

    &_footer {
      margin-top: 10px;
      justify-content: space-between;
    }

    &__reward {
      flex: initial;
    }

    &__metrics {
      flex: initial;
    } 

    & + & {
      margin-top: 0;
      margin-top: 32px;
    }
  }

  .reward {
    &_actions {
      display: flex;
    }

    &_action {
      & + & {
        margin-left: 16px;
      }
    }
  }

  .metrics {

  }

  .details {
    display: flex;
    flex-wrap: wrap;
  }

  .detail {
    flex: 1 1 50%;
    max-width: 50%;
    padding: 5px;

    &_name {
      font-size: $font-xs;
      margin-bottom: 15px;
    }

    &_value {
      font-size: $font-xs;
    }

    & + & {
      margin: 0;
    }
  }
}