@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  background: $white;
  border-radius: $radius-2;
  box-shadow: 0px 1.5px 6px rgba(51, 50, 48, 0.2);
}
