@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  flex-direction: column;
  padding: 28px 40px;
  overflow: hidden;
}

.header {
  display: flex;
  
  &_section {
    &__1 {
      flex: 3;
      display: flex;
      align-items: flex-start;
  
    }

    &__2 {
      flex: 1;
    }
  }
}

.avatar {
  margin-right: 20px;
}

.userInfo {
  display: flex;
  align-items: center;
}

.names {
  margin-right: 20px;

  &_name {
    font-size: $font-md;
    font-weight: 600;
    color: $brown;
  }

  &_username {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown;
    margin-top: 15px;
  }
}

.socials {
  display: flex;
}

.social {
  display: flex;
  align-items: center;

  &_icon {
    border-radius: 12px;
    height: 27px;
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      display: block;
      content: '';
    }

    margin-right: 12px;
  }

  &_link {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown;
  }

  &__instagram {
    .social_icon {
      background-color: $instagram;

      &::before {
        @include instagram;
      }
    }
  }

  &__twitter {
    .social_icon {
      background-color: $twitter;

      &::before {
        @include twitter;
      }
    }
  }

  & + & {
    margin-left: 20px;
  }
}

.status {
  display: flex;
  font-size: $font-md;

  &_title {
    font-weight: 500;
    margin-right: 4px;
    color: $brown-light;
  }

  &_value {
    font-weight: 600;
    color: $brown;
  }
}

.stats {
  display: flex;
}

.info {
  width: 100%;
}

.bio {
  white-space: pre-wrap;
  font-size: $font-sm;
  color: $brown-light;

  height: 200px;
  overflow-y: auto;
}

.stat {
  display: flex;
  margin-top: 50px;

  &_value {
    font-size: $font-sm;
    font-weight: 600;
    color: $brown-light;
    margin-right: 4px;
  } 

  &_label {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown-light;
  }

  & + & {
    margin-left: 27px;
  }
}

.content {
  display: flex;
  margin-top: 32px;
}

.section {
  flex: 1;

  &_title {
    font-size: $font-sm;
    font-weight: 500;
    font-style: italic;
    color: $brown-light;
    margin-bottom: 35px;
  }

  &_content {
    display: flex;
  }

  &__bio {
    flex: 1
  }

  &__reward {
    flex: 1;
  }

  &__staking {
    flex: 1;
  } 

  & + & {
    margin-left: 6px;
  }
}

.details {
  flex: 1;
}

.detail {
  &_name {
    font-size: $font-sm;
    font-weight: 500;
    color: $brown-light;
    margin-bottom: 15px;
  }

  &_value {
    font-size: 18px;
    font-weight: 600;
    color: $brown;
  }

  & + & {
    margin-top: 35px;
  }
}

@media (max-width: $sm) {
  .root {
    padding: 16px 24px;
  }

  .header {
    display: flex;

    &_section {
      &__1 {
        display: flex;
        flex-direction: column;
      }

      &__2 {
        margin-top: 16px;
      }
    }

    &_left {
      flex: 3;
      display: flex;
      align-items: flex-start;
      align-items: center;
    }

    &_right {
      flex: 1;
    }
  }

  .userInfo {
    display: flex;
    align-items: center;
  }

  .avatar {
    margin-right: 12px;
  }

  .names {
    margin: 0;

    &_name {
      font-size: $font-sm;
    }

    &_username {
      font-size: $font-xs;
      margin-top: 12px;
    }
  }

  .socials {
    display: flex;
    margin-top: 16px;
  }


  .social {
    display: flex;
    align-items: center;

    &_icon {
      margin-right: 12px;
    }

    &_link {
    }

    & + & {
      margin-left: 20px;
    }
  }

  .status {
    display: flex;
    font-size: $font-sm;

    &_title {
    }

    &_value {

    }
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .stats {
    display: flex;
  }

  .bio {}

  .stat {
    display: flex;
    margin-top: 26px;

    &_value {
      font-size: $font-sm;
      margin-right: 4px;
    } 

    &_label {
      font-size: $font-sm;
    }

    & + & {
      margin-left: 16px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .section {
    flex: initial;

    &_title {
      font-size: $font-sm;
      margin-bottom: 16px;
    }

    &_content {
      display: flex;
    }

    &__bio {
      flex: initial;
    }

    &__reward {
      flex: initial;
    }

    &__metrics {
      flex: initial;
    } 

    & + & {
      margin: 0;
      margin-top: 32px;
    }
  }

  .details {
    display: flex;
    flex-wrap: wrap;
  }

  .detail {
    flex: 1 1 50%;
    max-width: 50%;
    padding: 5px;

    &_name {
      font-size: $font-xs;
      margin-bottom: 16px;
    }

    &_value {
      font-size: $font-sm;
    }

    & + & {
      margin: 0;
    }
  }
}