@import 'style/variables/colors';

.root {
  display: flex;
  flex-direction: column;

  &_label {
    font-weight: 600;
  }

  &_control {
    position: relative;
    background: $white;
    color: $brown-light;
    border-radius: $radius-2;
    border: 2px solid $brown;

    &:hover {
      border: 2px solid $brown;
      box-shadow: none;
    }

    :global {
      .ant-input,
      .ant-input-password {
        border: none;
        box-shadow: none;
        padding: 0;
        font-size: inherit;
        line-height: initial;

        &:focus,
        &:hover {
          border: none;
          box-shadow: none;
        }
      }
    }

    &__hasError {
      &_control {
        border-color: $red;

        &:hover {
          border-color: $red;
          box-shadow: none;
        }
      }
    }
  }

  &_error {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    font-size: $font-xs;
    color: $red;
    white-space: nowrap;
  }

  &__isDisabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &__style {
    &_white {
      .label {
        color: $brown;
      }

      .control {
        background: $white;
        color: $brown-light;
        border: 2px solid $brown;

        &:hover {
          border: 2px solid $brown;
        }
      }
    }
  }

  &__size {
    &_xs {
      .root_label {
        font-size: $font-xs;
        // margin-bottom: $font-xs;
        margin-bottom: 4px;
      }

      .root_control {
        padding: calc($font-xs / 2) $font-xs;
        font-size: $font-xs;
      }
    }

    &_sm {
      .root_label {
        font-size: $font-sm;
        // margin-bottom: $font-sm;
        margin-bottom: 4px;
      }

      .root_control {
        padding: calc($font-sm / 2) $font-sm;
        font-size: $font-sm;
      }
    }

    &_md {
      .root_label {
        font-size: $font-md;
        // margin-bottom: $font-md;
        margin-bottom: 4px;
      }

      .root_control {
        padding: calc($font-md / 2) $font-md;
        font-size: $font-md;
      }
    }

    &_lg {
      .root_label {
        font-size: $font-lg;
        // margin-bottom: $font-lg;
        margin-bottom: 4px;
      }

      .root_control {
        padding: calc($font-lg / 2) $font-lg;
        font-size: $font-lg;
      }
    }
  }
}
