@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  flex-direction: column;
  padding: 28px 36px;
  position: relative;

  &_toggler {
    position: absolute;
    top: 28px;
    right: 28px;
  }

  &_separator {
    margin: 20px 0;
    height: 4px;
    background: $gray-light;
    border: 1px solid $brown-light;
    border-radius: 1px;
  }
}

@media (max-width: $sm) {
  .root {
    padding: 16px 24px;
  }
}
