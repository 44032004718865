@import 'style/variables/colors';

:global {
  .ant-notification {
    width: 300px;
  }
  
  .ant-notification-notice {
    padding: 8px;
    background: white;

    &-close {
      color: $black;

      &:hover {
        color: $black; 
      }
    }

    &-content {

    }

    &-message {
      font-size: $font-sm;
      color: $black;
    }

    &-description {
      margin-top: 8px;
      font-size: $font-xs;
      color: $black;
    }

    &__error {
      border: 1px solid $red;
    }

    &__success {
      border: 1px solid $green;
    }
  }
}