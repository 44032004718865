@import 'style/variables/colors';

.root {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $black;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  user-select: none;
  background: $white;
  border: 2px solid transparent;
  transition-duration: background 0.2s;

  &_text {
    display: flex;
    border-radius: inherit;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }

  &__isDisabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &__type_button {

  }

  &__type_link {
    &:hover {
      text-decoration: none;
    }
  }

  &__style {
    &_white {
      background: $white;
      border-color: $brown-light;

      .root_text {
        color: $brown-light;
      }

      &:hover {
        border-color: $brown-light;
        background: $brown-light;

        .root_text {
          color: $white;
        }
      }
    }

    &_blue {
      background: $white;
      border-color: $blue;

      .root_text {
        color: $blue;
      }

      &:hover {
        border-color: $blue;
        background: $blue;

        .root_text {
          color: $white;
        }
      }
    }

    &_blue-light {
      background: $white;
      border-color: $blue-light;

      .root_text {
        color: $blue-light;
      }

      &:hover {
        border-color: $blue-light;
        background: $blue-light;

        .root_text {
          color: $white;
        }
      }
    }

    &_green {
      background: $white;
      border-color: $green;

      .root_text {
        color: $green;
      }
      
      &:hover {
        border-color: $green;
        background: $green;
        
        .root_text {
          color: $white;
        }
      }
    }

    &_red {
      background: $white;
      border-color: $red;

      .root_text {
        color: $red;
      }
      
      &:hover {
        border-color: $red;
        background: $red;

        .root_text {
          color: $white;
        }
      }
    }

    &_yellow {
      background: $white;
      border-color: $yellow;

      .root_text {
        color: $yellow;
      }

      &:hover {
        border-color: $yellow;
        background: $yellow;

        .root_text {
          color: $brown-light;
        }
      }
    }

    &_gray-light {
      background: $gray-light;
      
      &:hover {
        border-color: $brown-light;
      }

      .root_text {
        color: $brown-light;
      }
    }
  }

  &__size {
    &_xs {
      padding: calc($font-xs / 2) $font-xs;

      .root_text {
        font-size: $font-xs;
      }
    }

    &_sm {
      padding: calc($font-sm / 2) $font-sm;
      
      .root_text {
        font-size: $font-sm;
      }
    }

    &_md {
      padding: calc($font-md / 2) $font-md;
      
      .root_text {
        font-size: $font-md;
      }
    }

    &_lg {
      padding: calc($font-lg / 2) $font-lg;
      
      .root_text {
        font-size: $font-lg;
      }
    }
  }
}