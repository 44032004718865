@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  align-items: center;
  cursor: pointer;

  &_text {
    font-weight: 600;
    font-size: $font-sm;
    color: $brown-light;
    margin-right: 8px;
    white-space: nowrap;
  }

  &__isToggled {
    .root_icon {
      &::before {
        @include arrow-up;
      }
    }
  }
  
  &_icon {
    &::before {
      display: block;
      content: '';
      @include arrow-down;
    }
  }
}

@media (max-width: $sm) {
  .root {
    &_text {
      font-size: $font-xxs;
      margin-right: 4px;
    }
  }
}
