@import 'style/variables/colors';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  white-space: nowrap;

  &_field {
    & + & {
      margin-left: 16px;
    }

    min-width: 150px;
  }
}

.list {
  &_item {
    & + & {
      margin-top: 40px;
    }
  }
}


@media (max-width: $sm) {
  .filter {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    &_field {

    }
  }

  .list {

  }
}
