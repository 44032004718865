@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  width: 100%;
}

.label {
  font-weight: 600;
}

.section {
  display: flex;
  align-items: center;
}

.prefix {
  margin-right: 8px;
  font-weight: 600;
}

.postfix {
  margin-left: 8px;
  font-weight: 600;
}

.control {
  width: 100%;
  position: relative;

  &__hasError {}
}

.error {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  font-size: 12px;
  color: $red;
  white-space: nowrap;
}

.switch {
  width: 100%;
}

:global {
  .ant-switch {
    border-radius: $radius-1;
    // border: solid 1px $brown-light;
    background-color: $white;
    width: 50px;

    &-handle {
      // top: 1px;
      // left: 1px;
      // top: 50%;
      // left: 50%;
      // height: 25px;
      // width: 25px;
      // transform: translateX(-50%) translateY(-50%);

      &::before {
        border: solid 1px $brown-light;
      }
    }

    &-inner {

    }

    &-checked {
      background: $gray;

      &::after {
        transform: translateY(-50%) translateX(125%);
      }
    }
  }
}

.root {
  &__isDisabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &__style {
    &_white {

    }
  }

  &__size {
    &_xs {
      .label {
        font-size: $font-xs;
        // margin-bottom: $font-xs;
        margin-bottom: 4px;
      }

      .prefix {
        font-size: $font-xs;
      }

      .postfix {
        font-size: $font-xs;
      }
      
      :global {}
    }

    &_sm {
      .label {
        font-size: $font-sm;
        // margin-bottom: $font-sm;
        margin-bottom: 4px;
      }

      .prefix {
        font-size: $font-sm;
      }

      .postfix {
        font-size: $font-sm;
      }

      :global {}
    }

    &_md {
      .label {
        font-size: $font-md;
        // margin-bottom: $font-md;
        margin-bottom: 4px;
      }

      .prefix {
        font-size: $font-md;
      }

      .postfix {
        font-size: $font-md;
      }

      :global {}
    }

    &_lg {
      .label {
        font-size: $font-lg;
        // margin-bottom: $font-lg;
        margin-bottom: 4px;
      }

      .prefix {
        font-size: $font-lg;
      }

      .postfix {
        font-size: $font-lg;
      }

      :global {
      }
    }
  }
}
