@import 'style/variables/grid';

@function gridContainerWidth($breakpoint) {
  @return map-get($containers, $breakpoint);
}

@mixin gridContainer($breakpoint) {
  // @include clearfix;
  margin-right: auto;
  margin-left: auto;
  width: gridContainerWidth($breakpoint);
}

@mixin gridBreakpoint($breakpoint) {
  @media #{inspect(map-get($gridBreakpoints, $breakpoint))} {
    @content;
  }
}

@mixin gridRow {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  margin: -($gridGap / 2);
}

@mixin gridFlexRect($height: 1) {
  &:before {
    content: "";
    display: block;
    padding-top: 100% * $height;
    float: left;
  }
}

@mixin gridFlexCol($ratio) {
  width: gridFlexColWidth($ratio);
  margin: $gridGap / 2;
}

@mixin gridFixedCol($breakpoint, $ratio) {
  width: gridFixedColWidth($breakpoint, $ratio);
  margin: $gridGap / 2;
}

@function gridFixedColWidth($breakpoint, $ratio) {
  $width: (map-get($containers, $breakpoint) + $gridGap) * $ratio;

  @return $width - $gridGap;
}

@function gridFlexColWidth($ratio) {
  @return calc(#{$ratio * 100%} - #{$gridGap});
}

@mixin clearfix {
  &::after {
    content: "\0020";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden;
  }
}
