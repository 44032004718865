@import 'style/variables/colors';
@import 'style/variables/grid';

.breadcrumbs {
  display: flex;

  &_item {
    & + & {
      // margin-left: 8px;
    }
  }
}

.defaultLayout {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  padding-top: 175px;
  padding-bottom: 110px;
  position: absolute;

  &_container {
    
  }

  &_header {
    width: 100%;
    position: fixed;
    background: $panel;
    top: 0;
    z-index: 2;
    height: 175px;
  }

  &_content {
    width: 100%;
    padding: 48px 0;
  }

  &_footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: $brown-light;
    height: 110px;
  }
}

@media (max-width: $sm) {
  .defaultLayout {
    padding-top: 164px;
    padding-bottom: 110px;
  
    &_container {
      
    }
  
    &_header {
      padding: 0 16px;
      height: 150px;
    }
  
    &_content {
      padding: 32px 16px;
    }
  
    &_footer {
      padding: 0 16px;
      height: 110px;
    }
  }
}