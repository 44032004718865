@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  width: 100%;
}

.label {
  font-weight: 600;
}

.section {
  display: flex;
  align-items: center;
}

.prefix {
  margin-right: 8px;
  font-size: $font-sm;
  font-weight: 600;
}

.postfix {
  margin-left: 8px;
  font-size: $font-sm;
  font-weight: 600;
}

.control {
  width: 100%;
  position: relative;

  // select {
  //   font-size: $font-sm;
  //   color: $black;
  //   padding: 0 8px;
  //   border-radius: $radius;
  //   border: 1px solid $gray;

  //   &:focus,
  //   &:hover {
  //     border: 1px solid $gray;
  //     box-shadow: none;
  //   }
  // }

  &__hasError {}
}

.error {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  font-size: 12px;
  color: $red;
  white-space: nowrap;
}

.select {
  width: 100%;

  :global {
    .ant-select-selector {
      border-radius: $radius-2;
      border: 2px solid transparent;
      font-weight: 500;

      &:focus,
      &:hover {
        border: 2px solid transparent;
        box-shadow: none;
      }
    }

    .ant-select-selection-search {
      right: 0 !important;
      left: 0 !important;
      position: absolute !important;

      &-input {
        width: 100%;
        height: 100%;
      }
    }

    .ant-select-selection-item {
      overflow: hidden;
      line-height: initial !important;
    }

    .ant-select-arrow {
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      width: auto;
      height: auto;
      margin: 0;

      & > span {
        display: none;
      }

      &::before {
        display: block;
        content: '';
        @include arrow-down;
      }
    }
  }

  &_dropdown {
    box-shadow: $shadow;
    border-radius: $radius-2 !important;
    border: 2px solid transparent;
    padding: initial !important;
    line-height: initial !important;
  }

  &_option {
    background: initial;
    min-height: initial;
    padding: initial;
    font-weight: 500;
    font-size: initial;
    line-height: initial;

    & + & {
      margin-top: 16px;
    }
  }
}

.root {
  &__isDisabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &__style {
    &_white {
      .control {
        :global {
          .ant-select-selector {
            background: $white;
            border-color: $brown-light;
            color: $brown-light;

            &:hover {
              border: 2px solid $brown-light !important;
            }
          }

          .ant-select {
            &:hover {
              .ant-select-selector {
                border: 2px solid $brown-light !important;
              }
            }
          }
        }
      }

      .select {
        &_dropdown {
          border-color: $brown-light;

          :global {
            .ant-select-item-option-active,//select_option
            .ant-select-item-option-selected {
              background: $gray-light;
              color: inherit;
            }
          }
        }
      }
    }
  }

  &__size {
    &_xs {
      .label {
        font-size: $font-xs;
        // margin-bottom: $font-xs;
        margin-bottom: 4px;
      }

      .select {
        &_option {
          padding: calc($font-xs / 2) $font-xs;
          font-size: $font-xs;
        }
      }
      
      :global {
        .ant-select-selector {
          padding: calc($font-xs / 2) $font-xs;
          font-size: $font-xs;
        }
        .ant-select-arrow {
          right: $font-xs;
        }
      }
    }

    &_sm {
      .label {
        font-size: $font-sm;
        // margin-bottom: $font-sm;
        margin-bottom: 4px;
      }

      .select {
        &_option {
          padding: calc($font-sm / 2) $font-sm;
          font-size: $font-sm;
        }
      }

      :global {
        .ant-select-selector {
          padding: calc($font-sm / 2) $font-sm;
          font-size: $font-sm;
        }

        .ant-select-arrow {
          right: $font-sm;
        }
      }
    }

    &_md {
      .label {
        font-size: $font-md;
        // margin-bottom: $font-md;
        margin-bottom: 4px;
      }

      .select {
        &_option {
          padding: calc($font-md / 2) $font-md;
          font-size: $font-md;
        }
      }

      :global {
        .ant-select-selector {
          padding: calc($font-md / 2) $font-md;
          font-size: $font-md;
        }

        .ant-select-arrow {
          right: $font-md;
        }
      }
    }

    &_lg {
      .label {
        font-size: $font-lg;
        // margin-bottom: $font-lg;
        margin-bottom: 4px;
      }

      .select {
        &_option {
          padding: calc($font-lg / 2) $font-lg;
          font-size: $font-lg;
        }
      }

      :global {
        .ant-select-selector {
          padding: calc($font-lg / 2) $font-lg;
          font-size: $font-lg;
        }

        .ant-select-arrow {
          right: $font-lg;
        }
      }
    }
  }
}