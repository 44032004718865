@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $radius-3;
  overflow: hidden;

  &__size {
    &_xxs {
      height: 30px;
      width: 30px;
    }

    &_xs {
      height: 50px;
      width: 50px;
    }

    &_sm {
      height: 70px;
      width: 70px;
    }

    &_md {
      height: 100px;
      width: 100px;
    }

    &_lg {
      height: 100px;
      width: 100px;
    }
  }
}

.image {
  width: 100%;
  height: 100%;
}

.placeholder {
  background: $yellow;
  width: 100%;
  height: 100%;
}