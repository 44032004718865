@mixin icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}


@mixin clubhouse {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url('/assets/icons/clubhouse.svg')
}

@mixin github {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url('/assets/icons/github.svg')
}

@mixin bscscan {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url('/assets/icons/bscscan.svg')
}

@mixin telegram {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url('/assets/icons/telegram.svg')
}

@mixin twitter {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url('/assets/icons/twitter.svg')
}

@mixin instagram {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url('/assets/icons/instagram.svg')
}


@mixin twitter {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/twitter.svg");
}

@mixin instagram {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/instagram.svg");
}

@mixin check {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/check.svg");
}

@mixin arrow-down {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/arrow.svg");
}

@mixin arrow-up {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/arrow.svg");
  transform: rotate(180deg);
}

@mixin arrow-right {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/arrow.svg");
  transform: rotate(-90deg);
}

@mixin external-link {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/external-link.svg");
}

@mixin phone {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/phone.svg");
}

@mixin logo-1 {
  @include icon;
  height: 16px;
  width: 16px;
  background-image: url("/assets/icons/logo-1.svg");
}

@mixin logo-2 {
  @include icon;
  height: 49px;
  width: 145px;
  background-image: url("/assets/icons/logo-2.svg");
}

@mixin logo-2-dark {
  @include icon;
  height: 49px;
  width: 145px;
  background-image: url("/assets/icons/logo-2-dark.svg");
}

@mixin page-404 {
  @include icon;
  width: 316px;
  height: 348px;
  // background-image: url("/assets/icons/page-404.svg");
}

@mixin money {
  @include icon;
  width: 20px;
  height: 20px;
  // background-image: url("/assets/icons/money.png");
}

@mixin laptop {
  @include icon;
  width: 20px;
  height: 20px;
  // background-image: url("/assets/icons/laptop.png");
}

@mixin copy {
  @include icon;
  width: 16px;
  height: 16px;
  background-image: url("/assets/icons/copy.svg");
}