@import 'style/mixins/grid';

.container {
  @include gridContainer(md);
}

.row {
  @include gridRow;
}

@for $i from 1 through 12 {
  .col__#{$i} {
    @include gridFlexCol($i / 12);
  }
}

.container__fluid {
  @include gridBreakpoint(xs) {
    @include gridContainer(xs);
  }

  @include gridBreakpoint(sm) {
    @include gridContainer(sm);
  }

  @include gridBreakpoint(md) {
    @include gridContainer(md);
  }

  @include gridBreakpoint(lg) {
    @include gridContainer(lg);
  }
  
  @include gridBreakpoint(xl) {
    @include gridContainer(xl);
  }
}