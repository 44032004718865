@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  background: $panel;

  &_left {
    flex: 0.6;
  }

  &_right {
    flex: 0.4;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb {
  display: flex;
  align-items: center;

  &_separator {
    &::before {
      display: block;
      content: '';
      @include arrow-right;
    }
    
    margin-right: 8px;
  }

  &_link {
    font-size: $font-sm;
    color: $brown-light;
    font-weight: 500;

    &__isActive {
      font-weight: 600;
    }
  }

  & + & {
    margin-left: 8px;
  }
}

.menu {
  &_select {
    min-width: 150px;
  }
}

@media (max-width: $sm) {
  .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    height: $font-xs * $font-scale * 4;
  }

  .breadcrumb {
    padding: 0;

    &_link {
      font-size: $font-xs;
      line-height: $font-xs * $font-scale;
    }

    &_separator {
      &::before {
        height: 13px;
        width: 13px;
      }
    }

    & + & {
      margin: 0;
    }
  }
}