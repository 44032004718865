@import 'style/variables/colors';
@import 'style/variables/grid';
@import 'style/mixins/grid';
@import 'style/mixins/icons';

.root {
  right: 16px;
  position: fixed;
  transition: all 250ms ease-in;
  min-width: 300px;
  max-width: 400px;
  max-height: 300px;
  overflow-y: auto;

  &__type {
    &_info {
      .root_box {
        border-color: $brown-light;
      }
    }

    &_danger {
      .root_box {
        border-color: $red;
      }
    }

    &_success {
      .root_box {
        border-color: $green;
      }
    }

    &_warning {
      .root_box {
        border-color: $yellow;
      }
    }
  }

  &_box {
    padding: 32px;
    border: 2px solid transparent;
  }

  &_title {
    font-size: $font-md;
    font-weight: 600;
  }

  &_description {
    margin-top: 32px;
    font-size: $font-md;
    font-weight: 500;
  }

  &_closeBtn {
    position: absolute;
    font-size: $font-md;
    font-weight: 600;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}


@media (max-width: $sm) {
  .root {
    &_box {
      padding: 22px;
    }

    &_title {
      font-size: $font-sm;
    }

    &_description {
      margin-top: 16px;
      font-size: $font-sm;
    }

    &_closeBtn {
      top: 10px;
      right: 10px;
      font-size: $font-sm;
    }
  }
}
