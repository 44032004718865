@import 'style/variables/colors';
@import 'style/variables/grid';

* {
  box-sizing: border-box;
  // line-height: 1;
  font-family: Montserrat, Nunito, Roboto, sans-serif !important;
}

html {
  // overflow-y: scroll
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: 100% !important;
  background: $panel;
  color: $brown !important;
}

a {
  color: $blue-light;

  &:hover {
    color: $blue-light;
    text-decoration: underline;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  &:-internal-autofill-selected {
    box-shadow: inset 0 0 0 50px #fff !important;
    color: #000 !important;
  }
}

html,
body {
  ::-webkit-scrollbar-thumb {
    background: $brown-light;
    border-radius: $radius-1;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 50px $gray-light;
    border-radius: $radius-1;
  }

  ::-webkit-scrollbar {
    // width: 8px;
  }

  ::selection {
    color: $brown-light;
    background: $gray-light;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

:global {
  // .producthunt-badge {
  //   position: fixed;
  //   left: 16px;
  //   bottom: 60px;
  //   z-index: 10;
  // }
  
  // @media (max-width: $sm) {
  //   .producthunt-badge {
  //     bottom: 60px;
  //   }
  // }

  // #crisp-chatbox a[data-maximized] {
  //   bottom: 60px!important;
  //   right: 16px!important;
  // }
}