@import 'style/variables/colors';

.root {
  // display: flex;
  // flex-direction: column;

  &__isDisabled {
    pointer-events: none;
    opacity: 0.3;
  }
}


.label {
  font-size: $font-sm;
  font-weight: 600;
  color: $brown;
  margin-bottom: 32px;
}

.section {
  // display: flex;
  // align-items: center;
}

.prefix,
.postfix {
  margin-right: 20px;
  font-size: $font-sm;
  font-weight: bold;
  color: #000
}

.prefix {
  margin-right: 20px;
}

.postfix {
  margin-left: 20px;
}

.error {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  font-size: $font-xs;
  color: $red;
  white-space: nowrap;
}

.control {
  position: relative;

  :global {
    .ant-slider {
      box-shadow: none;
      padding: 0;
      margin: 0;
      font-size: inherit;
      line-height: initial;
      border-radius: inherit;
      color: inherit;
      width: 100%;
      border-radius: $radius-2;
      height: 10px;

      &-rail {
        height: 100%;
        border-radius: $radius-2;
      }

      &-track {
        height: 100%;
      }

      &-handle {

      }
    }
  }

  &__hasError {
    .control {
      border-color: $red;

      &:hover {
        border-color: $red;
        box-shadow: none;
      }
    }
  }
}

.root {
  &__style {
    &_white {
      :global {
        .ant-slider {
          background: $white;
          
          border: 2px solid $brown-light;

          &-rail {
            background: $white;
          }

          &-track {
            background: $brown-light;
          }

          &-handle {
            border-color: $brown-light;
            &:focus {
              box-shadow: 0 0 0 5px rgb(85 83 73 / 12%);
            }
          }

          &:hover {
            .ant-slider-rail {
              background: $white
            }

            .ant-slider-track {
              background: $brown-light;
            }

            .ant-slider-handle {
              border-color: $brown-light;
            }
          }
        }
      }
    }

    &_gray-light {
      :global {
        .ant-slider {
          background: $gray-light;

          border: 2px solid $brown-light;

          &-rail {
            background: $gray-light;
          }

          &-track {
            background: $brown-light;
          }

          &-handle {
            border-color: $brown-light;
            &:focus {
              box-shadow: 0 0 0 5px rgb(85 83 73 / 12%);
            }
          }

          &:hover {
            .ant-slider-rail {
              background: $gray-light
            }

            .ant-slider-track {
              background: $brown-light;
            }

            .ant-slider-handle {
              border-color: $brown-light;
            }
          }
        }
      }
    }

  }

  &__size {
    &_xs {
      .control {
        // padding: calc($font-xs / 2) $font-xs;
        // font-size: $font-xs;
      }
    }

    &_sm {
      .control {
        // padding: calc($font-sm / 2) $font-sm;
        // font-size: $font-sm;
      }
    }

    &_md {
      .control {
        // padding: calc($font-md / 2) $font-md;
        // font-size: $font-md;
      }
    }

    &_lg {
      .control {
        // padding: calc($font-lg / 2) $font-lg;
        // font-size: $font-lg;
      }
    }
  }
}


// .root {
// }

// .section {
//   display: flex;
//   align-items: center;
// }

// .label {
//   font-size: $font-sm;
//   font-weight: bold;
//   color: #000;
//   margin-bottom: 14px;
// }

// .prefix {
//   margin-right: 20px;
//   font-size: $font-sm;
//   font-weight: bold;
//   color: #000
// }

// .control {
//   position: relative;

//   :global {
//     .ant-input-number {
//       height: 50px;
//       background: #fff;
//       font-size: $font-sm;
//       color: #000;
//       padding: 0 20px;
//       border-radius: $radius;
//       border: 1px solid transparent;

//       &-handler-wrap {
//         display: none;
//       }

//       &-input {
//         height: 100%;
//         padding: 0;
//       }

//       &-input-wrap {
//         height: 100%;
//       }

//       &:focus,
//       &:hover {
//         border: 1px solid transparent;
//         box-shadow: none;
//       }
//     }
//   }

//   &__hasError {
//     :global {
//       .ant-input-number {
//         border-color: $red;

//         &:focus,
//         &:hover {
//           border-color: $red;
//           box-shadow: none;
//         }
//       }
//     }
//   }
// }

// .error {
//   position: absolute;
//   top: calc(100% + 2px);
//   left: 0;
//   font-size: 12px;
//   color: $red;
//   white-space: nowrap;
// }

// .postfix {
//   margin-left: 20px;
//   font-size: $font-sm;
//   font-weight: bold;
//   color: #000
// }